<template>
  <div class="article-box">
    <el-table :data="articles" style="width: 100%" v-loading="loading">
      <el-table-column prop="serial" label="序号" type="index" align="center" width="150">
        <template slot-scope="scope">
          {{ (currpage - 1) * pagesize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="标题" align="center">
        <template slot-scope="scope">
          {{ scope.row.Title.slice(0,40) }} <span v-if="scope.row.Title.length>40">...</span>
          </template>
      </el-table-column>
      <!-- <el-table-column
        prop="times"
        label="作者"
        width="380">
      </el-table-column> -->
      <el-table-column sortable prop="InsertTime" label="发布时间" :formatter="InsertTime" align="center">
      </el-table-column>
      <el-table-column sortable prop="UpdateTime" label="审核时间" :formatter="UpdateTimes" align="center">
      </el-table-column>
      <el-table-column prop="examine" label="审核人" align="center">
        <!-- <template>
          {{ admin }}
        </template> -->
      </el-table-column>
      <el-table-column prop="Reason" label="拒绝原因" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
      </el-pagination>
    </div>

    <el-dialog title="拒绝原因" :visible.sync="dialogFormVisible" top="55px" :close-on-click-modal="false">
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="reason">
      </el-input>
      <el-button type="primary" class="btn" style="margin-top:10px" @click="Submit(article)">修改</el-button>
    </el-dialog>
  </div>
</template>
<script>
  import {
    articleList,
    articleExamine
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        articles: [], // 文章列表
        currpage: 1, // 初始页
        pagesize: 12, // 每页显示条目个数
        CountNum: 0, // 总条数
        loading: false, //加载框
        reason: '', // 拒绝原因
        article: {},
        dialogFormVisible: false, // 拒绝原因弹窗
        admin: '管理员'
      }
    },
    mounted() {
      this.ArticleList(1) // 初始化，获取文章列表
    },
    methods: {
      ArticleList(currpage) { // 文章列表
      this.loading = true
        const data = {
          page: currpage, // 页数
          limit: this.pagesize, // 总数
          status: 2 // 0待审核 1已审核 2已拒绝 3已冻结 10全部
        }
        articleList(data).then(res => {
          this.articles = res.data.List // 已拒绝文章列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      handleEdit(index, row) { // 编辑拒绝原因
        this.dialogFormVisible = true // 打开弹窗
        this.article = Object.assign({}, row)
      },
      UpdateTimes(row, column) { // 审核时间过滤
        return row.UpdateTime.slice(0, 16)
      },
      InsertTime(row, column) { // 发布时间过滤
        return row.InsertTime.slice(0, 16)
      },
      Submit(article) { // 提交修改拒绝原因
        // console.log(article.ID);
        const data = {
          ID: article.ID,
          status: 2,
          reason: this.reason
        }
        if (this.reason == '') {
          this.$message('请输入拒绝原因')
        } else {
          articleExamine(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.dialogFormVisible = false // 关闭弹窗
              this.reason = '' // 清空输入框
              this.ArticleList(this.currpage) // 成功通过后刷新视图
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      handleCurrentChange(val) { // 分页
        this.ArticleList(val)
        this.currpage = val
      }
    }
  }
</script>
<style lang="scss" scoped>
  .article-box {
    width: 100%;

    .el-pagination {
      margin-top: 20px;
    }

    .quill-editor {
      height: 300px;
      margin-bottom: 20px;
    }
  }
</style>
